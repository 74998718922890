import React, { createRef, useCallback, useEffect, useState, useRef } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Stack, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import 'jsoneditor/dist/jsoneditor.min.css'
import './JSONViewer.scss'
import JSONEditor from 'jsoneditor' // Ensure JSONEditor is imported correctly

interface IProps {
  isShow: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  title: string;
  json: object;
  handleJSON: (json: object) => void;
  handleSave: () => void;
  handleClose: () => void;
}

export const JSONViewer = ({ isShow, isLoading, isDisabled, title, json = {}, handleJSON, handleSave, handleClose }: IProps) => {
  const [isError, setIsError] = useState(false)
  const viewerRef = createRef<HTMLDivElement>()
  const editorRef = useRef<any>(null)
  const [JSONEditor, setJSONEditor] = useState<any>(null)

  const handleValidation = useCallback((errors: readonly any[]) => {
    setIsError(errors.length > 0)
  }, [])

  const handleInput = useCallback((json: object) => {
    handleJSON(json)
  }, [handleJSON])

  const expandAll = useCallback(() => {
    if (viewerRef.current && JSONEditor) {
      if (editorRef.current) {
        editorRef.current.destroy()
      }
      const options: any = {
        mode: isDisabled ? 'view' : 'code',
        onChange: () => handleInput(editorRef.current!.get()),
        onValidationError: (errors: any) => handleValidation(errors) // Specify 'any' type for 'errors'
      }
      const editor = new JSONEditor(viewerRef.current, options) // Ensure 'new' keyword is used
      editor.set(json)

      if (options.mode === 'tree' || options.mode === 'view') {
        editor.expandAll()
      }

      editorRef.current = editor
    }
  }, [viewerRef, JSONEditor, isDisabled, json, handleInput, handleValidation])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('jsoneditor').then((module) => {
        setJSONEditor(() => module.default)
      })
    }
  }, [])

  useEffect(() => {
    if (isShow && !isLoading && JSONEditor) {
      setTimeout(() => {
        expandAll()
      }, 0)
    }
    return () => {
      if (editorRef.current) {
        editorRef.current.destroy()
      }
    }
  }, [isShow, isLoading, expandAll, JSONEditor])

  useEffect(() => {
    if (isDisabled && JSONEditor) {
      expandAll()
    }
  }, [expandAll, isDisabled, JSONEditor])

  return (
    <Dialog
      open={isShow}
      onClose={handleClose}
      fullWidth
      maxWidth='md'
      disableEscapeKeyDown
      aria-labelledby='dialog-title'
      data-test='json-viewer-dialog'
    >
      <DialogTitle
        id='dialog-title'
        data-test='dialog-title'
        sx={{ m: 0, p: 2 }}
      >
        {title}
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {isLoading ? (
          <CircularProgress
            className='d-b m-l-auto m-r-auto'
            data-test='preloader'
          />
        ) : (
          <div
            ref={viewerRef}
            data-test='json-editor'
            style={{ height: '400px' }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Stack
          spacing={2}
          direction='row'
          justifyContent={'space-between'}
          width={'100%'}
          paddingX={2}
          paddingY={1}
        >
          <Button
            onClick={handleClose}
            data-test='cancel-button'
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
          {!isDisabled && (
            <Button
              variant='contained'
              disabled={isError}
              onClick={handleSave}
              data-test='save-button'
            >
              Save
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
